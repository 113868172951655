<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-page__body">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateForm"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="7">
                    <Field
                        v-model="fields.title"
                        name="title"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Title"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.description"
                        name="description"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Description"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field>

                    <Field
                        v-model="fields.h1"
                        name="h1"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="h1"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.url"
                        name="url"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="URL"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-select
                        v-model="fields.type"
                        :items="typeItems"
                        item-title="name"
                        item-value="id"
                        label="Тип"
                        color="indigo-darken-3"
                        variant="underlined"
                        :disabled="loading"
                    ></v-select>

                    <v-switch
                      v-model="fields.seo_noindex"
                      :label="fields.seo_noindex ? 'Страница скрыта от поисковых роботов' : 'Страница доступна для поиска'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </v-col>         

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>

</template>
<script>


import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'SeoEditPage',
    components: {
        Form,
        Field
    },
    data: () => ({
        title: '',
        id: null,
        fields: {
            'title': '',
            'description': '',
            'url': '',
            'h1': '',
            'noindex': false,
            'type': 0,
        },
        typeItems: [
            {
                id: 0,
                name: 'Страница'
            },
            {
                id: 1,
                name: 'Новости'
            },
            {
                id: 2,
                name: 'Категория каталога'
            },
            {
                id: 3,
                name: 'Продукт каталога'
            },
            {
                id: 4,
                name: 'Акции'
            },
            {
                id: 5,
                name: 'Бизнес страницы'
            },
        ],
        loading: false,
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        },
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/seo/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    if (key == 'noindex'){
                        this.fields[key] = fields[key] == 1 ? true : false

                    } else if(key === 'type') {
                        this.fields[key] = Number(fields[key])
                    } else {
                        this.fields[key] = fields[key]
                    }
                }
            }
        },
        selectCategories(item) {
            return {
                id: item.id,
                title: item.title,
                disabled: this.id == item.id
            }
        },
        submit() {

            this.$refs.createOrUpdateForm.validate().then(data => {
                if(data.valid) {
                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'type') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : '')
                    }
                }

                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/seo/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)
                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.replace({ name: 'seo_list' })
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'type') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : '')
                    }
                }

                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }
            }


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/seo/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        schema() {
            return yup.object({
                title: yup.string().min(2).max(200),
                description: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(160),
                h1: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(200),
                url: yup.string().required().min(1).max(200),
            })
        },
    }
}
</script>