import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

// import PerfectScrollbar from 'vue3-perfect-scrollbar'
// import 'vue3-perfect-scrollbar/dist/style.css'

// Moment-js
import moment from 'moment'
moment.locale('ru')

import { InstallCodeMirror } from "codemirror-editor-vue3"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

loadFonts()

const app = createApp(App)

app.use(router).use(store).use(vuetify).use(InstallCodeMirror).component('QuillEditor', QuillEditor)

app.config.globalProperties.$moment = moment

app.mount('#app')