<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-page__body">
        <Form
            @submit.prevent="submit"
            ref="createOrUpdateForm"
            :validation-schema="schema"
        >
            <v-row>
                <v-col sm="12" md="7">
                    <div v-if="fields.image" class="d-block mb-6">
                        <v-hover>
                            <template v-slot:default="{ isHovering, props }">
                                <v-img
                                    v-bind="props"
                                    :lazy-src="fields.image"
                                    max-height="200"
                                    :src="fields.image"
                                    class="elevation-4"
                                >
                                    <span
                                        v-show="isHovering"
                                        style="position: absolute; top: 0.8rem; right: 0.8rem;"
                                    >
                                        <v-btn
                                            v-if="!file"
                                            color="black"
                                            right
                                            size="small"
                                            icon
                                            variant="text"
                                            :href="fields.image"
                                            target="_blank"
                                        >
                                            <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-show="isHovering"
                                            color="red"
                                            size="small"
                                            icon
                                            variant="text"
                                            @click="clearFile"
                                        >
                                            <v-icon size="14">fa fa-trash</v-icon>
                                        </v-btn>
                                    </span>
                                </v-img>
                            </template>
                        </v-hover>
                    </div>

                    <div v-else class="d-flex w-100 mb-4">
                        <input ref="image" type="file" value="" class="d-none" accept="image/*" @change="setFile" />
                        <v-btn
                            color="indigo-darken-3"
                            variant="outlined"
                            block
                            @click="addImage"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить изображение
                        </v-btn>
                    </div>

                    <Field
                        v-model="fields.title"
                        name="title"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Название"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-autocomplete
                        v-model="fields.business_category_id"
                        label="Категория"
                        :items="categoryItems"
                        item-title="title"
                        item-value="id"
                        variant="underlined"
                        color="indigo-darken-3"
                        menu-icon="fa fa-angle-down"
                        :item-props="selectCategories"
                        clearable
                        clear-icon="fa fa-close"
                        no-data-text="Ничего не найдено"
                    >
                    </v-autocomplete>

                    <v-switch
                      v-model="editHtml"
                      :label="editHtml ? 'HTML' : 'Редактор'"
                      color="indigo-darken-3"
                      hide-details
                    ></v-switch>

                    <div class="app-page__files">
                        <div class="app-page__file item elevation-4" v-for="(file, key) in getFiles" :key="key">
                            <div class="item-file">
                                <div class="image">
                                    <template v-if="file.type === 'image'">
                                        <img v-if="file.path" :src="file.path" :alt="file.title" :title="file.title" />
                                    </template>
                                    <template v-if="file.type === 'video'">
                                        <video controls width="100%" :src="file.path"></video>
                                    </template>
                                    <template v-if="file.type === 'audio'">
                                        <audio controls :src="file.path"></audio>
                                    </template>
                                    <template v-if="file.type === 'file'">
                                        <v-icon size="124">fa fa-file</v-icon>
                                    </template>
                                </div>
                                <div class="item-action">
                                    <v-btn density="compact" icon @click="editFile(key, file)">
                                        <v-icon size="14">fa fa-pen</v-icon>
                                    </v-btn>

                                    <v-btn :href="file.path" target="_blank" density="compact" icon>
                                        <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                                    </v-btn>
                                    
                                    <v-btn density="compact" icon color="red" @click="removeFile(key, file)">
                                        <v-icon size="14">fa fa-trash</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                            <pre @dblclick="setFileDescription(file.id)">[file id="{{ file.id }}"]</pre>
                        </div>
                        <div class="w-100">
                            <input ref="files" type="file" value="" class="d-none" :accept="acceptFiles" @change="setFiles" multiple="multiple"/>
                            <v-btn
                                color="success"
                                density="compact"
                                :disabled="loading"
                                @click="addFiles"
                                :loading="loadingFile"
                            >
                                Добавить файл
                            </v-btn>
                        </div>
                    </div>

                    <div v-if="editHtml">
                        <Codemirror
                            v-model:value="fields.description"
                            :options="cmOptions"
                            border
                            placeholder="Описание"
                            :height="400"
                            :disabled="loading"
                        />
                    </div>

                    <div class="mb-3" v-else>
                        <quill-editor
                            v-model:content="fields.description"
                            placeholder="Описание" 
                            :enable="!loading"
                            contentType="html"
                            toolbar="full"
                        >
                        </quill-editor>
                    </div>


                    <Field
                        v-model="fields.sort"
                        name="sort"
                        v-slot="{ field, errors }"  
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Сортировка"
                            required
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-text-field>
                    </Field>

                    <v-switch
                      v-model="fields.status"
                      :label="fields.status ? 'Страница включена' : 'Страница выключена'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </v-col> 

                <v-col sm="12" md="5">
                    <h2>SEO</h2>
                    <Field
                        v-model="fields.seo_title"
                        name="seo_title"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="Title"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            :disabled="loading"
                        />
                    </Field>

                    <Field
                        v-model="fields.seo_description"
                        name="seo_description"
                        v-slot="{ field, errors }"  
                    >
                        <v-textarea
                            v-bind="field"
                            :model-value="field.value"
                            label="Description"
                            auto-grow
                            color="indigo-darken-3"
                            variant="underlined"
                            rows="1"
                            row-height="15"
                            :error-messages="errors"
                            :disabled="loading"
                        ></v-textarea>
                    </Field>

                    <Field
                        v-model="fields.seo_h1"
                        name="seo_h1"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="h1"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-alert
                        v-if="id"
                        border="start"
                        border-color="indigo-darken-3"
                        elevation="2"
                        class="mb-3"
                    >
                        <strong>Внимание!</strong>
                        При изменение URL или категории, все связанные страницы будут изменены на новый url: 
                        <strong>{{ getPathSeo }}</strong>
                    </v-alert>

                    <v-alert
                        v-else
                        border="start"
                        border-color="indigo-darken-3"
                        elevation="2"
                        class="mb-3"
                    >
                        Адрес страницы: 
                        <strong>{{ getPathSeo }}</strong>
                    </v-alert>

                    <Field
                        v-model="fields.seo_path"
                        name="seo_path"
                        v-slot="{ field, errors }"
                    >
                        <v-text-field
                            v-bind="field"
                            :model-value="field.value"
                            label="URL"
                            color="indigo-darken-3"
                            variant="underlined"
                            :error-messages="errors"
                            required
                            :disabled="loading"
                        />
                    </Field>

                    <v-switch
                      v-model="fields.seo_noindex"
                      :label="fields.seo_noindex ? 'Страница скрыта от поисковых роботов' : 'Страница доступна для поиска'"
                      color="indigo-darken-3"
                      hide-details
                      :disabled="loading"
                    ></v-switch>
                </v-col>           

                <v-col sm="12">
                    <div class="d-flex justify-space-between">
                        <v-btn
                            @click="$router.go(-1)"
                        >
                            Назад
                        </v-btn>
                        <v-btn
                            color="success"
                            :loading="loading"
                            @click="submit"
                        >
                            Сохранить
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
        </Form>
    </div>

    <RemovePopup
        :data="removeData"
        :open="openRemove"
        :title="titleRemove"
        :body="bodyRemove"
        @close="closeRemove"
        @action="removeItem"
        :loading="loadingRemove" />

    <EditFilePopup
        :data="editFileData"
        :open="openEditFile"
        :title="titleEditFile"
        @close="closeEditFile"
        @action="updateEditFile"
    />
</template>
<script>

// placeholder
import "codemirror/addon/display/placeholder.js";

// language
import "codemirror/mode/htmlembedded/htmlembedded.js";
//import "codemirror/mode/htmlmixed/htmlmixed.js";
// placeholder
import "codemirror/addon/display/placeholder.js";
// theme
import "codemirror/theme/dracula.css";

import RemovePopup from '@/components/popus/RemovePopup'
import EditFilePopup from '@/components/popus/EditFilePopup'

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'BusinessEditPage',
    components: {
        Form,
        Field,
        RemovePopup,
        EditFilePopup
    },
    data: () => ({
        editHtml: true,
        cmOptions: {
            mode: "text/html", // Language mode
            theme: "default", // Theme
            lineWrapping: true,
            indentUnit: 4
        },
        title: '',
        id: null,
        file: null,
        fields: {
            'title': null,
            'description': null,
            'business_category_id': null,
            'image': null,
            'sort': 0,
            'seo_title': '',
            'seo_description': '',
            'seo_path': '',
            'seo_h1': '',
            'seo_noindex': false,
            'status': true,
        },
        files: [],
        loading: false,
        categoryItems: [],

        defaultSeoPath: '/business',


        openRemove: false,
        removeData: {},
        loadingRemove: false,
        titleRemove: '',
        bodyRemove: '',
        
        loadingFile: false,

        openEditFile: false,
        editFileData: {},
        titleEditFile: '',
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
        this.getCategories()
    },
    watch: {
        id() {
            this.init()
        },
        editHtml(newVal) {
            if(!newVal) {
                this.fields.description = this.fields.description ? this.fields.description.replace(/<(.|\n)*?>/g, '') : null
            }
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/business/' + this.id + '/edit',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        getCategories() {
            this.$store.dispatch('API', {
                method: 'GET',
                url: '/business/list',
            }).then(res => {

                if(res.data.status === 'success') {
                    this.categoryItems = this.$store.getters.businessCategories(res.data.data)
                }
            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    if (key == 'status' || key == 'seo_noindex'){
                        this.fields[key] = fields[key] == 1 ? true : false

                    }else if(key == 'seo_path') {

                        if (fields[key].length > 1) {
                            let path = fields[key].split('/')

                            if(path.length > 2) {
                                this.fields[key] = path[path.length - 1]
                            }
                        }

                    } else {
                        this.fields[key] = fields[key]
                    }
                }

                if (key == 'files')
                    this.files = fields[key]
            }
        },
        selectCategories(item) {
            return {
                id: item.id,
                title: item.title,
                disabled: this.id == item.id
            }
        },
        addImage() {
            this.$refs.image.click()
        },
        setFile(e) {
            if(e.target.files[0]) {
                this.file = e.target.files[0]
                
                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e => {
                    this.fields.image = e.target.result
                }
            }
        },
        clearFile() {
            this.file = null
            this.fields.image = null
        },
        submit() {

            this.$refs.createOrUpdateForm.validate().then(data => {
                if(data.valid) {
                    if(this.id) this.update()
                    else this.create()
                }
            })
            
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image' && key !== 'seo_path' && key !== 'sort') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : '')
                    }
                }

                if(key === 'sort') {
                    data.append(key, value === 0 ? 0 : value)
                }

                if(key === 'seo_path') {
                    data.append(key, this.getPathSeo)
                }
            }

            if (this.file)
                data.append('image', this.file)

            for(let i = 0; i < this.files.length; i++){
                let file = this.files[i];
                data.append('new_files[' + i + ']', file.id)
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/business/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        if(res.data.data.title) this.setTitle(res.data.data.title)
                        this.setField(res.data.data)

                        if(res.data.data.id) this.$router.replace({ name: 'business_list' })
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {

            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key !== 'image' && key !== 'seo_path' && key !== 'sort') {
                    if(value === true || value === false) {
                        data.append(key, value ? 1 : 0)
                    }else{
                        data.append(key, value ? value : '')
                    }
                }

                if(key === 'sort') {
                    data.append(key, value === 0 ? 0 : value)
                }

                if(key === 'seo_path') {
                    data.append(key, this.getPathSeo)
                }
            }

            if (this.file)
                data.append('image', this.file)

            if(!this.file && !this.fields.image)
                data.append('remove_image', 1)


            this.$store.dispatch('API', {
                method: 'POST',
                url: '/business/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        if(res.data.data.title) this.setTitle(res.data.data.title)

                        this.setField(res.data.data)
                        this.getCategories()

                        this.file = null
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.createOrUpdateForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        async setFileDescription(id) {
            try {
                await navigator.clipboard.writeText('[file id="' + id + '"]')
            } catch($e) {
                console.log('Cannot copy')
            }
        },

        removeFile(i, item) {

            this.removeData = {
                key: i,
                data: item
            }

            let title = item.title ? item.title : '# ' + item.id

            this.openRemove = true
            this.titleRemove = 'Удалить файл ' + title
            this.bodyRemove = 'Вы уверены, что хотите удалить файл <b>' + title + '</b>?'

        },
        closeRemove() {
            this.openRemove = false

        },
        removeItem(data) {


            this.loadingRemove = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/remove-file',
                data: {
                    'id': data.data.id
                }
            }).then(res => {

                if(res.data.status === 'success') {

                    const files = [...this.files]

                    this.files = []

                    files.forEach((file, i) => {
                        if(i !== data.key) {
                            this.files.push(file)
                        }
                    })
                }

                this.loadingRemove = false
                this.openRemove = false
            })
        },
        editFile(i, item) {
            this.editFileData = {
                key: i,
                data: item
            }

            let title = item.title ? item.title : '# ' + item.id

            this.openEditFile = true
            this.titleEditFile = 'Редактировать файл ' + title
        },
        closeEditFile() {
            this.openEditFile = false
        },
        updateEditFile(data) {
            const files = [...this.files]

            this.files = []

            files.forEach((file, i) => {
                if(i === data.key) {

                    file.title = data.data.title
                    file.description = data.data.description

                    this.files.push(file)
                }else{
                    this.files.push(file)
                }
            })
        },
        setFiles(e) {

            this.loadingFile = true

            let files = e.target.files

            let data = new FormData()

            if(this.id)
                data.append('business_category_id', this.id)

            for(let i = 0; i < files.length; i++){
                let file = files[i];
                data.append('files[' + i + ']', file)
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/upload-files',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.files = this.files.concat(res.data.data)
                    }
                } 
                // else if(res.response && res.response.status == 422) {
                //     if(res.response.data.errors) {
                //         this.$refs.createOrUpdateProduct.setErrors(res.response.data.errors)
                //     }
                // }

                this.loadingFile = false
            })
        },
        addFiles() {
            this.$refs.files.click()
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        schema() {
            return yup.object({
                title: yup.string().required().min(2).max(255),
                description: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr),
                sort: yup.number().nullable(),
                seo_title: yup.string().min(2).max(200),
                seo_description: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(160),
                seo_h1: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(200),
                seo_path: yup.string().required().min(2).max(200),
            })
        },
        getFiles() {
            return this.files
        },
        getPathSeo() {

            let path = ''

            if(this.fields.business_category_id) {
                this.categoryItems.forEach(item => {
                    if(item.id === this.fields.business_category_id) {
                        path = item.seo_path + '/' + this.fields.seo_path
                    }
                })
            } else {
                path = this.defaultSeoPath + '/' + this.fields.seo_path
            }

            return path
        },
        acceptFiles() {
            return this.$store.state.acceptMimes
        }
    }
}
</script>