<template>
    <v-navigation-drawer
        absolute
        left
        elevation="6"
    >
        <v-list
            density="compact"
            nav
        >
            <v-list-item to="/dashboard">
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-house</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Главная</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-group
                collapse-icon="fa fa-angle-up"
                expand-icon="fa fa-angle-down"
            >

                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                    >
                        <template v-slot:prepend>
                            <v-icon size="20">fa fa-briefcase</v-icon>
                        </template>
                        <template v-slot:title>
                            <span class="font-weight-bold">Бизнес</span>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item
                    to="/business/page"
                    title="Страницы"
                ></v-list-item>
                <v-list-item
                    to="/business/stock"
                    title="Акции"
                ></v-list-item>
            </v-list-group>

            <v-list-group
                collapse-icon="fa fa-angle-up"
                expand-icon="fa fa-angle-down"
            >

                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                    >
                        <template v-slot:prepend>
                            <v-icon size="20">fa fa-list</v-icon>
                        </template>
                        <template v-slot:title>
                            <span class="font-weight-bold">Каталог</span>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item
                    to="/catalog/category"
                    title="Категории"
                ></v-list-item>
                <v-list-item
                    to="/catalog/product"
                    title="Товары"
                ></v-list-item>
                <!-- <v-list-item
                    to="/catalog/group-options"
                    title="Группы характеристик"
                ></v-list-item>
                <v-list-item
                    to="/catalog/options"
                    title="Опции"
                ></v-list-item>
                <v-list-item
                    to="/catalog/brand"
                    title="Бренд"
                ></v-list-item> -->
            </v-list-group>

            <!-- <v-list-item
                to="/stock"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-percent</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Акции</span>
                </template>
            </v-list-item> -->

            <!-- <v-list-item
                to="/article"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-newspaper</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Статьи</span>
                </template>
            </v-list-item> -->

            <!-- <v-list-item
                to="/seo"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-link</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">SEO URL</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>
            <v-list-item>
                <template v-slot:title>
                    Формы
                </template>
            </v-list-item> -->

            <!-- <v-list-item
                to='/form/callback'
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-phone</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Обратный звонок</span>
                </template>
            </v-list-item> -->

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-item
                to="/news"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-newspaper</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Новости</span>
                </template>
            </v-list-item>

            <v-list-item
                to="/page"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-web-awesome</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Страницы</span>
                </template>
            </v-list-item>

            <v-list-item
                to="/seo"
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-link</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">SEO URL</span>
                </template>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-group
                collapse-icon="fa fa-angle-up"
                expand-icon="fa fa-angle-down"
            >

                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                    >
                        <template v-slot:prepend>
                            <v-icon size="20">fa fa-square-check</v-icon>
                        </template>
                        <template v-slot:title>
                            <span class="font-weight-bold">Формы</span>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item
                    to="/form/callback"
                    title="Обратный звонок"
                ></v-list-item>
                <v-list-item
                    to="/form/form-business"
                    title="Заявка бизнес"
                ></v-list-item>
                <v-list-item
                    to="/form/order"
                    title="Заказ"
                ></v-list-item>
            </v-list-group>



            <!-- <v-list-item
                to='/user'
                disabled
            >
                <template v-slot:prepend>
                    <v-icon size="20">fa fa-users</v-icon>
                </template>
                <template v-slot:title>
                    <span class="font-weight-bold">Пользователи</span>
                </template>
            </v-list-item> -->

        </v-list>

        <!-- <template v-slot:append>
            <v-list
                density="compact"
                nav
            >
                <v-list-item
                    to="/setting"
                >
                    <template v-slot:prepend>
                        <v-icon size="20">fas fa-cog</v-icon>
                    </template>
                    <template v-slot:title>
                        <span class="font-weight-bold">Настройки</span>
                    </template>
                </v-list-item>
            </v-list>
        </template> -->
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'NavigationPanel'
}
</script>