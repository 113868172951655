<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-page__body">

        <v-row>
            <v-col sm="12" md="7">
                <v-text-field
                    v-model="fields.name"
                    label="Имя"
                    color="indigo-darken-3"
                    variant="underlined"
                />
                <v-text-field
                    v-model="fields.phone"
                    label="Телефон"
                    color="indigo-darken-3"
                    variant="underlined"
                />
                <v-textarea
                    v-model="fields.comment"
                    label="Комментарий"
                    auto-grow
                    color="indigo-darken-3"
                    variant="underlined"
                    rows="1"
                    row-height="15"
                ></v-textarea>
            </v-col> 

            <v-col sm="12" md="5">
                
            </v-col>           

            <v-col sm="12">
                <div class="d-flex justify-space-between">
                    <v-btn
                        @click="$router.go(-1)"
                    >
                        Назад
                    </v-btn>
                </div>
            </v-col>

        </v-row>
    </div>
</template>
<script>

export default {
    name: 'CallbackViewPage',
    components: {
    },
    data: () => ({
        title: '',
        id: null,
        fields: {
            'name': null,
            'phone': null,
            'comment': null
        }
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/form/callback/' + this.id + '/view',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    this.fields[key] = fields[key]
                }
            }
        },
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
    }
}
</script>