export default {
    isLoggedIn: state => !!state.token,

    subCategories: () => (categories) => {
        return getSubCategories(categories)
    },
    categories: () => (categories) => {
        return categoryItems(categories)
    },

    subBusinessCategories: () => (categories) => {
        return getSubBusinessCategories(categories)
    },

    businessCategories: () => (categories) => {
        return getBusinessCategories(categories)
    },

    subPageCategories: () => (categories) => {
        return getSubPages(categories)
    },

    pageCategories: () => (categories) => {
        return categoryPageItems(categories)
    },
}

function getSubBusinessCategories(categories) {
    let items = []
    categories.forEach(category => {
        items.push({
            'id': category.id,
            'business_category_id': category.business_category_id ? category.business_category_id : '',
            'title': category.title.trim(),
            'image': category.image ? category.image : '',
            'seo_path': category.seo_path ? category.seo_path : '',
            'subCategories': getSubBusinessCategories(category.pages.length > 0 ? category.pages : [])
        })
    })

    return items
}

function getBusinessCategories(categories, separator = null) {
    let items = []

    categories.forEach(category => {

        items.push({
            'id': category.id,
            'business_category_id': category.business_category_id ? category.business_category_id : '',
            'title': separator ? separator + ' ' + category.title.trim() : category.title.trim(),
            'seo_path': category.seo_path ? category.seo_path : '',
            'image': category.image ? category.image : '',
        })

        items.push.apply(items, getBusinessCategories(category.pages,  separator ? separator + '➖' : '➖'))
    })

    return items
}


function getSubCategories(categories) {
    let items = []
    categories.forEach(category => {
        items.push({
            'id': category.id,
            'category_id': category.category_id ? category.category_id : '',
            'title': category.title.trim(),
            'image': category.image ? category.image : '',
            'seo_path': category.seo_path ? category.seo_path : '',
            'subCategories': getSubBusinessCategories(category.pages.length > 0 ? category.pages : [])
        })
    })

    return items
}

function categoryItems (categories, separator = null) {
    let items = []

    categories.forEach(category => {
        items.push({
            'id': category.id,
            'category_id': category.category_id ? category.category_id : '',
            'title': category.title.trim(),
            'seo_path': category.seo_path ? category.seo_path : '',
            'image': category.image ? category.image : '',
        })

        items.push.apply(items, categoryItems(category.pages, separator ? separator + '➖' : '➖'))
    })

    return items
}

function getSubPages(categories) {
    let items = []
    categories.forEach(category => {
        items.push({
            'id': category.id,
            'page_id': category.page_id ? category.page_id : '',
            'title': category.title.trim(),
            'seo_path': category.seo_path ? category.seo_path : '',
            'subCategories': getSubPages(category.pages.length > 0 ? category.pages : [])
        })
    })

    return items
}

function categoryPageItems (categories, separator = null) {
    let items = []

    categories.forEach(category => {
        items.push({
            'id': category.id,
            'page_id': category.page_id ? category.page_id : '',
            'title': category.title.trim(),
            'seo_path': category.seo_path ? category.seo_path : '',
        })

        items.push.apply(items, categoryItems(category.pages, separator ? separator + '➖' : '➖'))
    })

    return items
}