<template>
    <Teleport to="body">
        <v-dialog
            v-model="dialog"
            max-width="400px"
        >
            <v-card>
                <v-card-title>
                    {{ title }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <Form
                        @submit.prevent="submit"
                        ref="editFile"
                        :validation-schema="schema"
                    >
                        <v-row>
                            <v-col cols="12">
                                <Field
                                    v-model="fields.title"
                                    name="title"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-text-field
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Название"
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        :error-messages="errors"
                                        required
                                        :disabled="loading"
                                    />
                                </Field>
                            </v-col>

                            <v-col cols="12">
                                <Field
                                    v-model="fields.description"
                                    name="description"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-textarea
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Описание"
                                        auto-grow
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        rows="1"
                                        row-height="15"
                                        :error-messages="errors"
                                        :disabled="loading"
                                    ></v-textarea>
                                </Field>
                            </v-col>
                        </v-row>

                    </Form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color=""
                        variant="text"
                        @click="close"
                        :disabled="loading"
                    >
                        Отмена
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        variant="text"
                        @click="submit"
                        :loading="loading"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Teleport>
</template>
<script>
import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'EditFilePopup',
    emits: ['action', 'close'],
    props: {
        title: {
            type: String,
            default: 'Редактировать'
        },
        open: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object
        },
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        dialog: false,
        loading: false,
        fields: {}
    }),
    watch: {
        open(val) {
            this.dialog = val
        },
        dialog(val) {
            if(this.open && !val) this.close()
        },
        data(val) {
            if(val.data) {
                this.fields = val.data
            }
        }
    },
    methods: {
        close() {
            this.fields = {}
            this.loading = false
            this.$refs.editFile.resetForm()
            this.$emit('close')
        },
        submit() {

            this.$refs.editFile.validate().then(data => {
                if(data.valid) {

                    this.loading = true

                    this.$store.dispatch('API', {
                        method: 'POST',
                        url: '/edit-file',
                        data: this.fields
                    }).then(res => {
                        if(res.data){
                            if(res.data.status === 'success') {
                                this.$emit('action', {
                                    key: this.data.key,
                                    data: res.data.data
                                })
                                this.close()
                            }
                        } else if(res.response && res.response.status == 422) {
                            if(res.response.data.errors) {
                                this.$refs.editFile.setErrors(res.response.data.errors)
                            }
                        }

                        this.loading = false
                    })
                }
            })
        }
    },
    computed: {
        schema() {
            return yup.object({
                title: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(255),
                description: yup.string().nullable(),
            })
        },
    }
}
</script>