<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-page__body">

        <v-row>
            <v-col sm="12" md="7">
                <v-list>
                    <v-list-item
                        v-for="product in fields.products"
                        :key="product.id"
                        :prepend-avatar="product.images && product.images.length ? product.images[0].path : ''"
                        :subtitle="'x' + `${product.pivot.quantity}`"
                        :title="product.title"
                    >
                        <template v-slot:append>
                            <v-btn
                                :to="{ name: 'product_edit', params: { id: product.id }}"
                                @click="setTitle(product.title)"
                                size="small"
                                icon
                                variant="text"
                            >
                                <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                            </v-btn>
                        </template>
                    </v-list-item>
                </v-list>

                <v-text-field
                    v-model="fields.name"
                    label="Имя"
                    color="indigo-darken-3"
                    variant="underlined"
                />
                <v-text-field
                    v-model="fields.phone"
                    label="Телефон"
                    color="indigo-darken-3"
                    variant="underlined"
                />
                <v-textarea
                    v-model="fields.comment"
                    label="Комментарий"
                    auto-grow
                    color="indigo-darken-3"
                    variant="underlined"
                    rows="1"
                    row-height="15"
                ></v-textarea>
            </v-col> 

            <v-col sm="12" md="5">
                
            </v-col>           

            <v-col sm="12">
                <div class="d-flex justify-space-between">
                    <v-btn
                        @click="$router.go(-1)"
                    >
                        Назад
                    </v-btn>
                </div>
            </v-col>

        </v-row>
    </div>
</template>
<script>

export default {
    name: 'FormOrderViewPage',
    components: {
    },
    data: () => ({
        title: '',
        id: null,
        fields: {
            'name': null,
            'phone': null,
            'comment': null,
            'products': []
        }
    }),
    mounted () {
        if(this.$route.params.id) this.id = this.$route.params.id
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.get()
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/form/order/' + this.id + '/view',
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.setField(res.data.data)

                }

                this.loading = false

            })
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    this.fields[key] = fields[key]
                }
            }
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
    }
}
</script>