<template>

    <slot v-if="$slots.action" name="action" :open="openDialog" :disabled="loadingItem"></slot>

    <v-btn
        v-else
        @click="openDialog"
        :disabled="loadingItem"
        color="success"
        density="compact"
        class="mt-3"
    >
        Добавить товар
    </v-btn>

    <Teleport to="body">
        <v-dialog
            v-model="dialog"
            max-width="400px"
        >
            <v-card
                
            >
                <v-card-title>
                    Добавить товар к опции
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    
                    <Form
                        @submit.prevent="submit"
                        ref="optionAddForm"
                        :validation-schema="schema"
                    >
                        <v-row>

                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="fields.product_id"
                                    label="Товар"
                                    :items="products"
                                    item-title="title"
                                    item-value="id"
                                    variant="underlined"
                                    color="indigo-darken-3"
                                    menu-icon="fa fa-angle-down"
                                    clearable
                                    clear-icon="fa fa-close"
                                    no-data-text="Ничего не найдено"
                                    @keyup="searchProductList"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12">
                                <Field
                                    v-model="fields.value"
                                    name="value"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-text-field
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Значение опции"
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        :error-messages="errors"
                                        required
                                        :disabled="loading"
                                    />
                                </Field>
                            </v-col>

                            <v-col cols="12">
                                <Field
                                    v-model="fields.description"
                                    name="description"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-textarea
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Описание"
                                        auto-grow
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        rows="1"
                                        row-height="15"
                                        :error-messages="errors"
                                        :disabled="loading"
                                    ></v-textarea>
                                </Field>
                            </v-col>
                        </v-row>

                    </Form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="warning"
                        variant="text"
                        @click="close"
                        :disabled="loading"
                    >
                        Отмена
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        variant="text"
                        @click="submit"
                        :loading="loading"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Teleport>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'OptionAddOrUpdatePopup',
    emits: ['create', 'update'],
    props: {
        optionId: {
            type: Number,
            default: null
        },
        id: {
            type: Number,
            default: null
        },
        loadingItem: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        dialog: false,
        loading: false,
        fields: {
            'product_id': null,
            'value': '',
            'description': '',
        },
        products: [],
        loadingSearch: false,
        timerSearch: null,
    }),
    watch: {
    },
    methods: {
        openDialog () {
            if(this.optionId) {
                this.dialog = true
                this.loading = false
                this.products = []
                this.loadingSearch = false
                clearTimeout(this.timerSearch)
                this.timerSearch = null
                this.fields = {
                    'product_id': null,
                    'value': '',
                    'description': ''
                }

                if(this.id) {
                    this.get()
                }
            }
        },
        close() {
            this.dialog = false
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/catalog/option/'  + this.optionId + '/product/' + this.id,
            }).then(res => {

                if(res.data.status === 'success') {
                    this.setField(res.data.data)
                }

                this.loading = false

            })
        },
        submit() {
            this.$refs.optionAddForm.validate().then(data => {
                if(data.valid) {
                    if(this.id) this.update()
                    else this.create()
                }
            })
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    this.fields[key] = fields[key]
                }

                if(key === 'product') {
                    this.products.push(fields[key])
                }
            }
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }else{
                    data.append(key, value ? value : '')
                }
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/option/'  + this.optionId + '/product/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        this.setField(res.data.data)
                        this.$emit('create', res.data.data)
                        this.close()

                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.optionAddForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }else{
                    data.append(key, value ? value : '')
                }
            }

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/option/'  + this.optionId + '/product/' + this.id + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.setField(res.data.data)
                        this.$emit('update', res.data.data)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.optionAddForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        searchProductList(event) {

            clearTimeout(this.timerSearch)

            this.timerSearch = setTimeout(() => {

                this.loadingSearch = true

                this.$store.dispatch('API', {
                    method: 'GET',
                    url: '/catalog/search-product',
                    params: {
                        q: event.target.value
                    }
                }).then(res => {
                    if(res.data) {
                        if(res.data.status === 'success') {
                            this.products = res.data.data
                        }
                    }

                    this.loading = false
                })


            }, 500)
        }
    },
    computed: {
        schema() {
            return yup.object({
                value: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(1).max(60),
                description: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr)
            })
        },
    }
}
</script>