import persistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls"
let ls = new SecureLS({ encodingType: process.env.VUE_APP_STORAGE_ENCODE, isCompression: false, encryptionSecret: process.env.VUE_APP_STORAGE_SECRET })

const paths = [
    'token',
    'itemsLimit'
]

let localStorage = persistedState({
        key: process.env.VUE_APP_NAME_STORAGE,
        paths: paths
    })

if(process.env.VUE_APP_STORAGE_ENCRYPTION && process.env.VUE_APP_STORAGE_ENCRYPTION === 'true') {
    localStorage = persistedState({
        key: process.env.VUE_APP_NAME_STORAGE,
        paths: paths,
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        }
    })
}

export default [
    localStorage
]