import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

import MainLayout from '@/layouts/MainLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import SignIn from '@/pages/auth/SignIn.vue'
import ResetPassword from "@/pages/auth/ResetPassword.vue"
import NewPassword from "@/pages/auth/NewPassword.vue"

import Dashboard from '@/pages/Dashboard.vue'

import PagePage from '@/pages/page/PagePage'
import PageListPage from '@/pages/page/PageListPage'
import PageEditPage from '@/pages/page/PageEditPage'

import SeoPage from '@/pages/seo/SeoPage'
import SeoListPage from '@/pages/seo/SeoListPage'
import SeoEditPage from '@/pages/seo/SeoEditPage'

import NewsPage from '@/pages/news/NewsPage'
import NewsListPage from '@/pages/news/NewsListPage'
import NewsEditPage from '@/pages/news/NewsEditPage'

import BusinessPage from '@/pages/business/BusinessPage'
import PageBusinessPage from '@/pages/business/PageBusinessPage'
import BusinessListPage from '@/pages/business/BusinessListPage'
import BusinessEditPage from '@/pages/business/BusinessEditPage'
import BusinessStockPage from '@/pages/business/BusinessStockPage'
import BusinessStockListPage from '@/pages/business/BusinessStockListPage'
import BusinessStockEditPage from '@/pages/business/BusinessStockEditPage'

import CatalogPage from '@/pages/catalog/CatalogPage.vue'
import CategoryPage from '@/pages/catalog/CategoryPage.vue'
import CategoryListPage from '@/pages/catalog/CategoryListPage.vue'
import CategoryEditPage from '@/pages/catalog/CategoryEditPage.vue'
import ProductPage from '@/pages/catalog/ProductPage.vue'
import ProductListPage from '@/pages/catalog/ProductListPage.vue'
import ProductEditPage from '@/pages/catalog/ProductEditPage.vue'


import FormPage from '@/pages/forms/FormPage'
import CallbackPage from '@/pages/forms/callback/CallbackPage'
import CallbackListPage from '@/pages/forms/callback/CallbackListPage'
import CallbackViewPage from '@/pages/forms/callback/CallbackViewPage'

import FormBusinessPage from '@/pages/forms/business/BusinessPage'
import FormBusinessListPage from '@/pages/forms/business/BusinessListPage'
import FormBusinessViewPage from '@/pages/forms/business/BusinessViewPage'

import FormOrderPage from '@/pages/forms/order/FormOrderPage'
import FormOrderListPage from '@/pages/forms/order/FormOrderListPage'
import FormOrderViewPage from '@/pages/forms/order/FormOrderViewPage'

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            title: 'Главная',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: Dashboard,
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                redirect: '/',
            },

            {
                path: 'business',
                name: 'business',
                component: BusinessPage,
                meta: {
                    title: 'Бизнес',
                },
                redirect: '/business/page',
                children: [
                    {
                        path: 'page',
                        name: 'business_page',
                        component: PageBusinessPage,
                        meta: {
                            title: 'Страницы',
                        },
                        children: [
                            {
                                path: '',
                                name: 'business_page_list',
                                component: BusinessListPage,
                            },
                            {
                                path: ':id',
                                name: 'business_list',
                                component: BusinessListPage,
                                meta: {
                                    title: 'Бизнес',
                                }
                            },
                            {
                                path: ':id/edit',
                                name: 'business_edit',
                                component: BusinessEditPage,
                                meta: {
                                    title: 'Редактирование страницы',
                                }
                            },
                            {
                                path: 'add',
                                name: 'business_add',
                                component: BusinessEditPage,
                                meta: {
                                    title: 'Добавить бизнес страницу',
                                }
                            },
                        ]
                    },
                    {
                        path: 'stock',
                        name: 'business_stock_page',
                        component: BusinessStockPage,
                        meta: {
                            title: 'Акции',
                        },
                        children: [
                            {
                                path: '',
                                name: 'business_stock_list',
                                component: BusinessStockListPage
                            },
                            {
                                path: ':id/edit',
                                name: 'business_stock_edit',
                                component: BusinessStockEditPage,
                                meta: {
                                    title: 'Редактирование акции',
                                }
                            },
                            {
                                path: 'add',
                                name: 'business_stock_add',
                                component: BusinessStockEditPage,
                                meta: {
                                    title: 'Добавить акцию',
                                }
                            },
                        ]
                    },
                ]
            },

            {
                path: 'catalog',
                name: 'catalog',
                component: CatalogPage,
                meta: {
                    title: 'Каталог',
                },
                redirect: '/catalog/category',
                children: [
                    {
                        path: 'category',
                        name: 'category_page',
                        component: CategoryPage,
                        meta: {
                            title: 'Категории',
                        },
                        children: [
                            {
                                path: '',
                                name: 'category',
                                component: CategoryListPage
                            },
                            {
                                path: ':id',
                                name: 'category_list',
                                component: CategoryListPage,
                                meta: {
                                    title: 'Категория',
                                }
                            },
                            {
                                path: ':id/edit',
                                name: 'category_edit',
                                component: CategoryEditPage,
                                meta: {
                                    title: 'Редактирование категории',
                                }
                            },
                            {
                                path: 'add',
                                name: 'category_add',
                                component: CategoryEditPage,
                                meta: {
                                    title: 'Добавить категорию',
                                }
                            },
                        ]
                    },
                    {
                        path: 'product',
                        name: 'product_page',
                        component: ProductPage,
                        meta: {
                            title: 'Товары',
                        },
                        children: [
                            {
                                path: '',
                                name: 'product',
                                component: ProductListPage
                            },
                            {
                                path: ':id/edit',
                                name: 'product_edit',
                                component: ProductEditPage,
                                meta: {
                                    title: 'Редактирование товара',
                                }
                            },
                            {
                                path: 'add',
                                name: 'product_add',
                                component: ProductEditPage,
                                meta: {
                                    title: 'Добавить товар',
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: 'news',
                name: 'news',
                component: NewsPage,
                meta: {
                    title: 'Новости',
                },
                children: [
                    {
                        path: '',
                        name: 'news_list',
                        component: NewsListPage,
                    },
                    {
                        path: ':id/edit',
                        name: 'news_edit',
                        component: NewsEditPage,
                        meta: {
                            title: 'Редактирование новости',
                        }
                    },
                    {
                        path: 'add',
                        name: 'news_add',
                        component: NewsEditPage,
                        meta: {
                            title: 'Добавить новость',
                        }
                    },
                ]
            },
            {
                path: 'page',
                name: 'page',
                component: PagePage,
                meta: {
                    title: 'Страницы',
                },
                children: [
                    {
                        path: '',
                        name: 'page_list',
                        component: PageListPage,
                    },
                    {
                        path: ':id/edit',
                        name: 'page_edit',
                        component: PageEditPage,
                        meta: {
                            title: 'Редактирование страницы',
                        }
                    },
                    {
                        path: 'add',
                        name: 'page_add',
                        component: PageEditPage,
                        meta: {
                            title: 'Добавить страницу',
                        }
                    },
                ]
            },
            {
                path: 'seo',
                name: 'seo',
                component: SeoPage,
                meta: {
                    title: 'SEO',
                },
                children: [
                    {
                        path: '',
                        name: 'seo_list',
                        component: SeoListPage,
                    },
                    {
                        path: ':id/edit',
                        name: 'seo_edit',
                        component: SeoEditPage,
                        meta: {
                            title: 'Редактирование seo',
                        }
                    },
                    {
                        path: 'add',
                        name: 'seo_add',
                        component: SeoEditPage,
                        meta: {
                            title: 'Добавить seo',
                        }
                    },
                ]
            },

            {
                path: 'form',
                name: 'form',
                component: FormPage,
                meta: {
                    title: 'Форма обратной связи',
                },
                redirect: '/form/callback',
                children: [
                    {
                        path: 'callback',
                        name: 'form_callback',
                        component: CallbackPage,
                        children: [
                            {
                                path: '',
                                name: 'form_callback_list',
                                meta: {
                                    title: 'Форма обратной связи',
                                },
                                component: CallbackListPage
                            },
                            {
                                path: ':id/view',
                                name: 'form_callback_view',
                                component: CallbackViewPage,
                                meta: {
                                    title: 'Просмотр',
                                }
                            },
                        ]
                    },
                    {
                        path: 'form-business',
                        name: 'form_business',
                        component: FormBusinessPage,
                        children: [
                            {
                                path: '',
                                name: 'form_business_list',
                                meta: {
                                    title: 'Заявки бизнес',
                                },
                                component: FormBusinessListPage
                            },
                            {
                                path: ':id/view',
                                name: 'form_business_view',
                                component: FormBusinessViewPage,
                                meta: {
                                    title: 'Просмотр',
                                }
                            },
                        ]
                    },
                    {
                        path: 'order',
                        name: 'form_order',
                        component: FormOrderPage,
                        children: [
                            {
                                path: '',
                                name: 'form_order_list',
                                meta: {
                                    title: 'Заказы',
                                },
                                component: FormOrderListPage
                            },
                            {
                                path: ':id/view',
                                name: 'form_order_view',
                                component: FormOrderViewPage,
                                meta: {
                                    title: 'Просмотр',
                                }
                            },
                        ]
                    }

                    // {
                    //     path: '',
                    //     name: 'seo_list',
                    //     component: SeoListPage,
                    // },
                    
                    // {
                    //     path: 'add',
                    //     name: 'seo_add',
                    //     component: SeoEditPage,
                    //     meta: {
                    //         title: 'Добавить seo',
                    //     }
                    // },
                ]
            },
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayout,
        redirect: '/auth/sign-in',
        meta: {
            requiresAuth: false,
            title: 'Авторизация',
        },
        children: [
            {
                path: 'sign-in',
                name: 'sign_in',
                component: SignIn,
                meta: {
                    title: 'Вход в аккаунт',
                }
            },
            {
                path: 'reset',
                name: 'reset_password',
                component: ResetPassword,
                meta: {
                    title: 'Сбросить пароль',
                }
            },
            {
                path: 'new-password/:id',
                name: 'new_password',
                component: NewPassword,
                meta: {
                    title: 'Создание пароля',
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // strict: true
})

router.beforeEach((to, from, next) => {

    const currentUser = store.getters.isLoggedIn
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !currentUser) {
        const loginpath = window.location.pathname
        next({ name: 'auth', query: { from: loginpath } })
    } else if (!requiresAuth && currentUser) {
        // if(to.name !== 'permission')
        //     next({ name: 'permission' })
        // else
        //     next()
        next({ name: 'main' })
    } else {
        next()
    }
})

export default router