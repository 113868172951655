<template>

    <slot v-if="$slots.action" name="action" :open="openDialog" :disabled="loadingItem"></slot>

    <v-btn
        v-else
        @click="openDialog"
        :disabled="loadingItem"
        color="success"
        density="compact"
        class="mt-3"
    >
        Создать опцию
    </v-btn>

    <Teleport to="body">
        <v-dialog
            v-model="dialog"
            max-width="400px"
        >
            <v-card
                
            >
                <v-card-title v-if="optionId">
                    Редактировать опцию
                </v-card-title>
                <v-card-title v-else>
                    Создать опцию
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    
                    <Form
                        @submit.prevent="submit"
                        ref="optionForm"
                        :validation-schema="schema"
                    >
                        <v-row>
                            <v-col cols="12">
                                <Field
                                    v-model="fields.name"
                                    name="name"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-text-field
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Название опции"
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        :error-messages="errors"
                                        required
                                        :disabled="loading"
                                    />
                                </Field>
                            </v-col>

                            <v-col cols="12">
                                <Field
                                    v-model="fields.description"
                                    name="description"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-textarea
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Описание"
                                        auto-grow
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        rows="1"
                                        row-height="15"
                                        :error-messages="errors"
                                        :disabled="loading"
                                    ></v-textarea>
                                </Field>
                            </v-col>

                            <v-col cols="12">
                                <Field
                                    v-model="fields.type"
                                    name="type"
                                    v-slot="{ field, errors }"  
                                >
                                    <v-select
                                        v-bind="field"
                                        :model-value="field.value"
                                        label="Тип"
                                        :items="types"
                                        item-title="name"
                                        item-value="type"
                                        color="indigo-darken-3"
                                        variant="underlined"
                                        :error-messages="errors"
                                        :disabled="loading"
                                    ></v-select>
                                </Field>
                            </v-col>
                        </v-row>

                    </Form>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="warning"
                        variant="text"
                        @click="close"
                        :disabled="loading"
                    >
                        Отмена
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        variant="text"
                        @click="submit"
                        :loading="loading"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </Teleport>
</template>
<script>

import { Form, Field } from 'vee-validate'
import ru from "yup-locale-ru"
import * as yup from 'yup'

yup.setLocale(ru)

export default {
    name: 'OptionPopup',
    emits: ['create', 'update'],
    props: {
        optionId: {
            type: Number,
            default: null
        },
        productId: {
            type: String,
            default: null
        },
        loadingItem: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Form,
        Field
    },
    data: () => ({
        dialog: false,
        loading: false,
        fields: {
            'name': '',
            'description': '',
            'type': 0
        },
        types: [
            {
                type: 0,
                name: 'Значение'
            }
        ]
    }),
    watch: {
    },
    methods: {
        openDialog () {
            if(this.productId) {
                this.dialog = true
                this.loading = false
                this.fields = {
                    'name': '',
                    'description': '',
                    'type': 0
                }

                if(this.optionId) {
                    this.get()
                }
            }
        },
        close() {
            this.dialog = false
        },
        get() {
            this.loading = true

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/catalog/option/' + this.optionId,
            }).then(res => {

                if(res.data.status === 'success') {
                    this.setField(res.data.data)
                }

                this.loading = false

            })
        },
        submit() {
            this.$refs.optionForm.validate().then(data => {
                if(data.valid) {
                    if(this.optionId) this.update()
                    else this.create()
                }
            })
        },
        setField(fields) {
            for (let key in fields) {
                if(this.fields[key] !== undefined) {
                    this.fields[key] = fields[key]
                }
            }
        },
        create() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }else{
                    data.append(key, value ? value : '')
                }
            }

            data.append('product_id', this.productId)

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/option/create',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {

                        this.setField(res.data.data)
                        this.$emit('create', res.data.data)

                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.optionForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        },
        update() {
            this.loading = true

            let data = new FormData()

            for (let [key, value] of Object.entries(this.fields)) {
                if(key === 'type') {
                    data.append(key, value === 0 ? 0 : value)
                }else{
                    data.append(key, value ? value : '')
                }
            }

            data.append('product_id', this.productId)

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/option/' + this.optionId + '/update',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: data
            }).then(res => {
                if(res.data) {
                    if(res.data.status === 'success') {
                        this.setField(res.data.data)
                        this.$emit('update', res.data.data)
                    }
                } else if(res.response && res.response.status == 422) {
                    if(res.response.data.errors) {
                        this.$refs.optionForm.setErrors(res.response.data.errors)
                    }
                }

                this.loading = false
            })
        }
    },
    computed: {
        schema() {
            return yup.object({
                name: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).min(2).max(255),
                description: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr),
                type: yup.string().required()
            })
        },
    }
}
</script>