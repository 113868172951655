<template>
    <h1 class="text-h4 mb-1">{{ titleHeading }}</h1>

    <div class="app-page__filter">
        <v-row 
            no-gutters
        >
            <v-col 
                sm="12"
                md="8"
            >
                <v-row
                    class="justify-space-between align-center"
                    no-gutters
                >
                    <v-col 
                        sm="8"
                        md="8"
                        class="pr-2"
                    >
                        <v-text-field
                            v-model="filter.search"
                            clearable
                            label="Поиск"
                            prepend-icon="fa fa-search"
                            variant="outlined"
                            density="compact"
                            hide-details
                            clear-icon="fa fa-close"
                            :loading="loadingFilter ? 'indigo-darken-3' : false"
                            @keyup="setFilter(true)"
                            @click:clear="setFilter"
                        ></v-text-field>
                    </v-col>
                    <v-col 
                        sm="4"
                        md="2"
                        class="pr-2"
                    >
                        <v-select
                            v-model="filter.searchType"
                            label="Поиск по"
                            :items="searchTypeItems"
                            item-title="name"
                            item-value="value"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            :disabled="loadingFilter"
                        ></v-select>
                    </v-col>
                    <v-col 
                        sm="4"
                        md="2"
                    >
                        <v-select
                            v-model="filter.status"
                            clearable
                            label="Статус"
                            :items="searchStatusItems"
                            item-title="name"
                            item-value="value"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            clear-icon="fa fa-close"
                            :disabled="loadingFilter"
                            @update:modelValue="setFilter"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            

            <v-col 
                sm="12"
                md="4"
            >
                <v-row
                    class="justify-end align-center"
                    no-gutters
                >
                    <v-col 
                        sm="12"
                        md="2"
                        class="pl-2"
                    >
                        <v-select
                            v-model="limitSet"
                            label="На стр."
                            :items="limitItems"
                            variant="outlined"
                            hide-details
                            density="compact"
                            menu-icon="fa fa-angle-down"
                            :disabled="loadingFilter"
                            @update:modelValue="setFilter"
                        ></v-select>
                    </v-col>
                    <v-col 
                        sm="12"
                        md="10"
                        class="text-right"
                    >
                        <v-btn
                            color="success"
                            :to="{name: 'category_add'}"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-plus</v-icon>
                            </template>
                            Добавить страницу
                        </v-btn>
                        <!-- <v-btn
                            v-if="$route.params.id"
                            color="info"
                            class="ml-2"
                            :to="{ name: 'category_edit', params: { id: $route.params.id }}"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-cog</v-icon>
                            </template>
                            Настроить
                        </v-btn>
                        <v-btn
                            v-else
                            color="info"
                            class="ml-2"
                            to="/catalog"
                        >
                            <template v-slot:prepend>
                                <v-icon size="14">fa fa-cog</v-icon>
                            </template>
                            Настроить
                        </v-btn> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>

    <v-skeleton-loader
        :loading="preLoading"
        type="table-row@5"
        class="w-100"
    >
        <div class="app-page__body">
        
            <div class="app-page__body-list title">
                <div>#</div>
                <div>Название</div>
                <div>Сорт.</div>
                <div>Статус</div>
                <div>Создан</div>
                <div>Обновлен</div>
                <div>Действия</div>
            </div>

            <div
                class="app-page__body-list"
                v-for="(item, key) in getItems"
                :key="key"
            >
                <div class="col">{{ item.id }}</div>
                <div class="col">
                    <router-link
                        v-if="item.pages.length > 0"
                        :to="{ name: 'category_list', params: { id: item.id }}"
                        @click="setTitle(item.title)"
                    >
                        {{ item.title }}
                    </router-link>
                    <span v-else>{{ item.title }}</span>
                </div>
                <div class="col">{{ item.sort }}</div>
                <div class="col" v-html="item.statusText"></div>
                <div class="col">{{ item.created }}</div>
                <div class="col">{{ item.updated }}</div>
                <div class="col">
                    <v-btn
                        :href="`https://neka.pro${item.seo_path}`"
                        target="_blank"
                        size="small"
                        icon
                        variant="plain"
                        :disabled="!item.seo_path"
                    >
                        <v-icon size="14">fa fa-arrow-up-right-from-square</v-icon>
                    </v-btn>

                    <v-btn
                        :color="item.status ? 'error' : 'success'"
                        size="small"
                        icon
                        variant="plain"
                        @click="editStatus(key, item)"
                    >
                        <v-icon size="14">fa fa-power-off</v-icon>
                    </v-btn>

                    <v-btn
                        :to="{ name: 'category_edit', params: { id: item.id }}"
                        @click="setTitle(item.title)"
                        size="small"
                        icon
                        variant="plain"
                    >
                        <v-icon size="14">fa fa-cog</v-icon>
                    </v-btn>

                    <v-btn
                        color="red"
                        size="small"
                        icon
                        variant="plain"
                        @click="removePage(key, item)"
                    >
                        <v-icon size="14">fa fa-trash</v-icon>
                    </v-btn>
                </div>

                <div
                    v-if="loadingItem[key]"
                    class="loading"
                >
                    <v-progress-circular
                        color="indigo-darken-3"
                        indeterminate
                    ></v-progress-circular>
                </div>

            </div>

            <div v-if="!getItems.length" class="d-flex text-center w-100 pa-2 justify-center align-center">
                Нет данных
            </div>
        

            <div v-if="pageTotal > 1" class="text-center pt-1 pb-1">
                <v-pagination
                    v-model="page"
                    :length="pageTotal"
                    :total-visible="7"
                    density="compact"
                    prev-icon="fas fa-chevron-left"
                    next-icon="fas fa-chevron-right"
                ></v-pagination>
            </div>
        </div>
    </v-skeleton-loader>

    <RemovePopup
        :data="removeData"
        :open="openRemove"
        :title="titleRemove"
        :body="bodyRemove"
        @close="closeRemove"
        @action="removeItem"
        :loading="loadingRemove" />
</template>
<script>
import RemovePopup from '@/components/popus/RemovePopup'

export default {
    name: 'CategoryListPage',
    components: {
        RemovePopup
    },
    data: () => ({
        page: 1,
        title: '',
        loading: false,
        items: [],
        limitItems: [10, 20, 50, 100],
        offset: 0,
        limitSet: 20,
        total: 0,
        searchTypeItems: [
            {
                'value': 'id',
                'name': 'ID'
            },
            {
                'value': 'name',
                'name': 'Название'
            }
        ],
        searchStatusItems: [
            {
                'value': 0,
                'name': 'Выключен'
            },
            {
                'value': 1,
                'name': 'Включен'
            }
        ],
        loadingFilter: false,
        filter: {
            searchType: 'name',
            search: '',
            status: null
        },
        timerSearch: null,

        loadingItem: {},

        openRemove: false,
        removeData: {},
        loadingRemove: false,
        titleRemove: '',
        bodyRemove: ''
    }),
    mounted () {
    },
    watch: {
        $route: {
            handler(to) {

                if(!to.params.id) this.title = ''
                else this.filter.search = ''

                this.$store.commit('Update', {
                    key: 'preloader',
                    value: true
                })

                this.init()
            }
        },
        page() {
            
            this.offset = ((this.limit * this.page) - this.limit)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            this.get()
        }
    },
    methods: {
        init() {
            this.items = []
            this.offset = 0

            this.get()
        },
        get() {
            this.loading = true

            let filter = {}

            let categoryId = ''

            if(this.$route.params.id) categoryId = '/' + this.$route.params.id

            if(this.filter.search) {
                filter.search = this.filter.search
                filter.type = this.filter.searchType
            }

            if(this.filter.status !== null) {
                filter.status = this.filter.status
            }

            this.$store.dispatch('API', {
                method: 'GET',
                url: '/catalog/category' + categoryId,
                params: {
                    'limit': this.limit,
                    'offset': this.offset,
                    'filter': filter
                }
            }).then(res => {

                if(res.data.status === 'success') {

                    if(res.data.data.title) this.setTitle(res.data.data.title)

                    this.total = res.data.data.total

                    this.items = res.data.data.items
                }

                this.loading = false
                this.loadingFilter = false

                this.$store.commit('Update', {
                    key: 'preloader',
                    value: false
                })
            })
        },
        setTitle(title = '') {
            if(title) {
                this.$store.commit('Update', {
                    key: 'titlePage',
                    value: title
                })

                this.title = title
            }
        },
        setFilter(input=false) {

            this.$store.commit('Update', {
                key: 'itemsLimit',
                value: this.limitSet
            })

            clearTimeout(this.timerSearch)

            this.$store.commit('Update', {
                key: 'preloader',
                value: true
            })

            if(input) {

                this.timerSearch = setTimeout(() => {
                    this.loadingFilter = true

                    this.init()

                    this.timerSearch = null
                }, 500)

            }else{
                this.init()
                this.loadingFilter = false
            }
        },
        editStatus(i, item) {

            this.loadingItem[i] = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/category/' + item.id + '/status',
            }).then(res => {

                if(res.data.status === 'success') {

                    item.status = item.status == 1 ? 0 : 1

                    item.updated_at = this.$moment()

                    this.items[i] = item
                }

                delete this.loadingItem[i]
            })

        },
        removePage(i, item) {

            this.removeData = {
                key: i,
                item: item
            }

            this.openRemove = true
            this.titleRemove = 'Удалить категорию #' + item.id
            this.bodyRemove = 'Вы уверены, что хотите удалить категорию <b>' + item.title + '</b>?'

        },
        closeRemove() {
            this.openRemove = false

        },
        removeItem(data) {

            this.loadingRemove = true
            this.loadingItem[data.key] = true

            this.$store.dispatch('API', {
                method: 'POST',
                url: '/catalog/category/' + data.item.id + '/remove',
            }).then(res => {

                if(res.data.status === 'success') {
                    delete this.items[data.key]
                }

                delete this.loadingItem[data.key]

                this.loadingRemove = false
                this.openRemove = false

                this.get()
            })
        }
    },
    computed: {
        titleHeading() {
            return this.title || this.$route.meta.title
        },
        getItems() {

            let items = []

            this.items.forEach(item => {

                if(item.status) item.statusText = '<span class="text-success">Включен</span>'
                else item.statusText = '<span class="text-error">Выключен</span>'

                if(item.created_at) {
                    item.created = this.$moment(item.created_at).calendar(null, {
                        sameDay: '[Сегодня], HH:mm:ss',
                        nextDay: '[Завтра], HH:mm:ss',
                        nextWeek: 'DD.MM.YYYY HH:mm:ss',
                        lastDay: 'DD.MM.YYYY HH:mm:ss',
                        lastWeek: 'DD.MM.YYYY HH:mm:ss',
                        sameElse: 'DD.MM.YYYY HH:mm:ss'
                    })
                }else{
                    item.created = ''
                }

                if(item.updated_at) {
                    item.updated = this.$moment(item.updated_at).calendar(null, {
                        sameDay: '[Сегодня], HH:mm:ss',
                        nextDay: '[Завтра], HH:mm:ss',
                        nextWeek: 'DD.MM.YYYY HH:mm:ss',
                        lastDay: 'DD.MM.YYYY HH:mm:ss',
                        lastWeek: 'DD.MM.YYYY HH:mm:ss',
                        sameElse: 'DD.MM.YYYY HH:mm:ss'
                    })
                }else{
                    item.updated = ''
                }

                items.push(item)
            })

            return items
        },
        preLoading() {
            return this.$store.state.preloader
        },
        preloaderGlobal() {
            return this.$store.state.preloaderGlobal
        },
        pageTotal() {

            if(!this.total) return 0

            return Math.ceil(this.total/this.limit)
        },
        limit() {
            return this.$store.state.itemsLimit
        }
    },
    created() {

        this.limitSet = this.limit

        this.$store.commit('Update', {
            key: 'preloader',
            value: true
        })
        this.init()        
    },
    unmounted() {
        
    }
}
</script>