<template>
    <v-layout class="rounded rounded-md">

        <AppBar />

        <NavigationPanel />

        <v-main class="d-flex" style="height: 100vh;">
            <div class="app">
                
                <BreadcrumbsList />

                <div class="app__content">
                    <router-view/>
                </div>

            </div>
        </v-main>
        <v-footer 
            app
            height="40"
        >
            <div class="text-center w-100">
                © {{ new Date().getFullYear() }} - <a href="https://neka.pro" target="_blank" title="Neka"><strong>Neka</strong></a>
            </div>
        </v-footer>
    </v-layout>
</template>

<script>

import AppBar from '@/components/AppBar'
import NavigationPanel from '@/components/NavigationPanel'
import BreadcrumbsList from '@/components/BreadcrumbsList'

export default {
    name: 'MainLayout',
    components: {
        AppBar,
        NavigationPanel,
        BreadcrumbsList,
    },
    data: () => ({
    }),
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$store.dispatch('API', {
                method: 'POST',
                url: '/init'
            }).then(res => {
                if(res.data){
                    if(res.data.status === 'success') {
                        let data = res.data.data

                        this.$store.commit('Update', {
                            key: 'user',
                            value: data.user
                        })

                        this.$store.commit('Update', {
                            key: 'acceptMimes',
                            value: data.file_mimes
                        })
                    }
                }
            })
        },
        initData() {
            this.retryTimes = 0
            this.connecting = false
        },
    },
    computed: {
    },
    unmounted() {
    },
}
</script>
